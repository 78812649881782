import React from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'js-components'

const defaultStyle = {
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, .8)',
  },
  content: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    position: 'relative',
    backgroundColor: 'transparent',
    border: 'none',
    overflow: 'visible',
  },
}

export default class Modal extends React.Component {
  constructor() {
    super()

    this.state = {
      modalIsOpen: false,
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal() {
    //document.getElementById('blurWrapper').classList.add('blur')
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    //document.getElementById('blurWrapper').classList.remove('blur')
    this.setState({ modalIsOpen: false })
  }

  render() {
    const {
      button: Button,
      modalContent: ModalContent,
      hideCloseButton,
      ...modalProps
    } = this.props

    var effectiveStyles = {
      overlay: {
        ...defaultStyle.overlay,
        ...this.props.overlayStyle
      },
      content: {
        ...defaultStyle.content,
        ...this.props.contentStyle
      }
    }

    return (
      <div>
        <Button onClick={this.openModal} />
        <ReactModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={effectiveStyles}
          {...modalProps}
        >
          {!hideCloseButton && <CloseButton onClick={this.closeModal}>
            <CloseButtonLineFirst />
            <CloseButtonLineSecond />
          </CloseButton>}
          <ModalContent onRequestClose={this.closeModal} />
        </ReactModal>
      </div>
    )
  }
}

Modal.propTypes = {
  button: PropTypes.func,
  modalContent: PropTypes.func,
}

const CloseButton = styled.button`
  background-color: ${colors.rgba(colors.white, 0.1)};
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  height: 2rem;
  outline: none;
  padding: 0;
  top: 1rem;
  left: 1rem;
  position: fixed;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  width: 2rem;
`

const CloseButtonLine = styled.span`
  background-color: white;
  border-radius: 0.125rem;
  display: block;
  height: 0.125rem;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 1rem;
`

const CloseButtonLineFirst = styled(CloseButtonLine)`
  transform: translate(-50%, -50%) rotate(45deg);
`

const CloseButtonLineSecond = styled(CloseButtonLine)`
  transform: translate(-50%, -50%) rotate(-45deg);
`
